.private-layout {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    padding: 20px;
    flex-grow: 1;

    &__outlet {
      border-radius: 10px;
      padding: 20px;
      height: 100%;
      background-color: #1b1b2b;
      max-width: 750px;
      margin: 0 auto;
    }
  }
}

.navigation-component {
  background-color: #f89a26;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

  &__title {
    display: flex;

    &__name {
      padding: 5px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    gap: 10px;

    &__entry {
      padding: 5px;
      border-radius: 10px;

      &.active {
        background-color: white;
        color: black;
      }
    }
  }

  &__account {
    display: flex;
    flex-direction: row;
    gap: 10px;

    &__entry {
      padding: 5px;
      border-radius: 10px;

      &.active {
        background-color: white;
        color: black;
      }
    }
  }
}
