.dashboard-page {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
}

.private-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;

  &__content {
    flex-grow: 1;
  }
}

.fair-marketing-page {
  &__table {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__column {
      font-size: 1.2rem;
      padding: 5px;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      background-color: white;
      color: black;
    }
  }
}

.account-page {
  &__logout-button {
    & button {
      color: black !important;
      background-color: white !important;
    }
  }
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;

  .login-form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
}

.user-detail-page {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__select {
    display: flex;
    border-radius: 20px;
  }

  &__content {
    user-select: all;

    >h4 {
      margin-top: 20px;
    }

    p {
      margin: 5px 0;
    }
  }

  &__general {
    display: flex;
    justify-content: space-between;

    &__avatar {
      img {
        aspect-ratio: 1 / 1;
        height: 150px;
        width: 150px;
      }
    }
  }

  &__medbed-license {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__entry {
      border-radius: 20px;
      border: 1px solid #f89a26;
      padding: 10px;
      position: relative;
    }


    &__trash-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      transition: color linear 125ms;

      &:hover {
        color: #ce0b65;
      }
    }

    &__trash-popup {
      &__title {
        color: #ce0b65;
        font-size: 2rem;
      }
    }
  }

  &__medbed {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &__entry {
      color: black;
      padding: 10px;
      border-radius: 20px;
      background-color: #f89a26;

    }
  }

  &__medbed-license-creator {
    &__amount {
      .MuiInput-input {
        color: black !important;
      }
    }
  }

  &__loyalty {
    display: flex;
    justify-content: space-evenly;

    p {
      text-align: center;
    }
  }
}

.business-genius-page {
  &__select {
    display: flex;
    border-radius: 20px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: start;

    &__header {
      display: flex;
      flex-direction: column;
    }

    &__status {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .button-component {
        width: auto;
        padding: 0;
      }
    }

    &__root-folders {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__folder-detail {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 10px;
      border: 2px solid #f89a26;

      &__subfolders {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}